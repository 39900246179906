<template>
  <div class="p-16">
    <h2>CILO Privacy Policy</h2>
    <br />
    <p>Last updated on Dec 15, 2021</p>
    <br />
    <p>
      These terms and services constitute a legally binding agreement (the
      “Agreement”) between you (also referred to herein as “You”, “Your”,
      “User(s),” “Artist(s),” or “Collector(s),”) and CILO Corp. a company
      operating the CILO platform and organized under the laws of Republic of Korea
      (“CILO Corp.,” “the Company”, “we”, “our” or “us” and together with you,
      the “Parties”) located at 19, Nonhyeon-ro 87-gil, Gangnam-gu, Seoul, Republic of Korea. 
      These Terms govern your use of the website located at
      cilo.io (the “Site”), and all related tools, mobile
      applications, web applications, decentralized applications, smart
      contracts, and APIs located at any of CILO Corp.'s websites, including
      without limitation, successor website(s) or application(s) thereto (the
      “Platform”).
    </p>
    <br />
    <p>
      By entering into this Agreement, and/or by you participating in the CILO
      Platform, you expressly acknowledge that you understand this Agreement and
      accept all of its terms. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND
      CONDITIONS OF THIS AGREEMENT, YOU MAY NOT PARTICIPATE IN THE OFFERING.
    </p>
    <br />
    <h2>1. Definitions</h2>
    <br />
    <h3>CILO Items</h3>
    <br />
    <p>
      All Artwork (“Art”, “Work(s)”, “Artwork(s)”, or “Creation(s)”) on the
      CILO Platform is represented by a unique cryptographic token exclusively
      Minted by one of CILO’s approved Artists. Each CILO Item (“Item(s)”,
      “Token(s)”, “Non-fungible token(s)”, or “NFT(s)”) is Minted by an Artist
      using the CILO Smart Contracts based on an Artist’s original Artwork.
      CILO Items are forever tracked and stored on the Ethereum blockchain,
      providing the Collector of a CILO Item with a permanent record of
      authenticity and ownership.
    </p>
    <br />
    <h3>Creating CILO Items</h3>
    <br />
    <p>
      Only Artists approved by CILO are able to use the Platform to “Mint” and
      sell CILO Items. Artwork Minted and sold on the CILO Marketplace
      includes, but is not limited to: visual works, audiovisual works,
      animations, audio, photographs, 3D works, GIFS, and other creative digital
      works.
    </p>
    <br />
    <h3>Applying to be a CILO Artist</h3>
    <br />
    <p>
      Artists seeking an invitation to create and list works for sale on the
      CILO Platform must submit a request by email found at the bottom of
      CILO marketplace. CILO has unilateral discretion in curating its
      Artist Network and makes no guarantees or promises that any Artists will
      be approved, even if the Artist was invited by a member of the CILO Team
      to submit the request.
    </p>
    <br />

    <h3>Artist Whitelisting and minting</h3>
    <br />
    <p>
      Approved Artists are “Whitelisted” and granted permission to Mint CILO
      Items using one or more of our Smart Contracts. To create a CILO Item,
      Approved Artists must upload an original digital Artwork, provide
      information about the artwork, authenticate the work, initiate a request
      to a token minting Smart Contract, and then and only then – the Artist has
      Minted a unique, cryptographic token representing a digital version of the
      artwork.
    </p>
    <br />

    <h3>Artist Agrees to Mint only “Original” Works</h3>
    <br />
    <p>
      By minting a CILO Item, Artists expressly represent and warrant that
      their Work is an original creation. Artists are prohibited from minting
      Works consisting of unlicensed or unauthorized copyrighted content,
      including any imagery, design, audio, video, human likeness, or other
      unoriginal content not created by the Artist, not authorized for use by
      the Artist, not in the public domain, or otherwise without a valid claim
      of fair use. Artist can only mint works that they personally created and
      must refrain from minting works to which copyright ownership is unknown or
      disputed (i.e., commissioned works or “works made for hire”).
    </p>
    <br />

    <h3>CILO Copyright Community Guidelines</h3>
    <br />
    <p>
      Copyright is the law of art. As a digital art platform, we take copyright
      law seriously and encourage our Artists and Collectors to learn more about
      their rights. Please visit the CILO Copyright Community Guidelines for
      more information on copyright law, the difference between “original” and
      “infringing” content, and the fair use defense.
    </p>
    <br />

    <h3>Artists Must Have Authority to Mint, Display, and Sell the Work</h3>
    <br />
    <p>
      Artist expressly represents and warrants that works Minted on the CILO
      Platform contain only original artistic content otherwise authorized for
      use by the Artist. To the extent a Work contains unoriginal content,
      including content from Works by other CILO Artists, the minting Artist
      further represents and warrants that it has permission to incorporate the
      unoriginal content. Artist represents and warrants that the sale, display
      or performance of minted CILO Item on the Platform is not a violation of
      any agreement, contract, or obligation owed to a third party.
    </p>
    <br />

    <h3>Artists May Be Removed from the Platform</h3>
    <br />
    <p>
      Failure to abide by these Terms and/or the Community Guidelines may result
      in, without limitation, suspension or deletion of the Artist’s account,
      revocation of the Artist’s minting privileges and other permissions to the
      CILO Smart Contracts or Platform, delisting the Artist’s items on the
      Site, or paying monetary damages. CILO has the unilateral authority and
      discretion to remove, suspend, or revoke Artists’ access to the CILO
      Smart Contracts or any other aspect of the Platform.
    </p>
    <br />

    <h3>Artist Indemnifies and Releases CILO</h3>
    <br />
    <p>
      Artist hereby agrees to indemnify CILO and be held liable for any claim
      against CILO arising out of the Artist’s breach of these Terms. Artist
      hereby releases and forever discharges CILO from any damages or causes
      of action resulting from a sale of any of the Artist’s listed CILO Items
      occurring after the Artist’s breach of these Terms.
    </p>
    <br />

    <h3>CILO Marketplace</h3>
    <br />
    <p>
      The CILO Platform facilitates a peer-to-peer digital art Marketplace and
      auction house where Artists, Collectors, and other Users can sell,
      purchase, list for auction, make offers, and bid on CILO Items.
      Collectors can obtain CILO Items by making an offer accepted by the
      Artist, purchasing at an established List Price, or bidding on Items
      available in one of our revolutionary smart contract-enabled auctions.
    </p>
    <br />

    <h3>Purchasing CILO Items with a List Price</h3>
    <br />
    <p>
      CILO Items are optionally offered for immediate acceptance at a List
      Price (in ETH) established by the Artist. Collectors can purchase CILO
      Items with a List Price through the Site by sending an equivalent amount
      of ETH to a Smart Contract configured to initiate a transfer of the CILO
      Item, plus additional fees and gas.
    </p>
    <br />

    <h3>Making Offers on CILO Items</h3>
    <br />
    <p>
      Users can make offers on all listed CILO Items through the Site. Offers
      on the CILO Marketplace are legally binding, revocable offers to
      purchase the Item capable of immediate acceptance by the Owner of the
      Item. By making an offer, the Collector agrees to temporarily send and
      lose control over an amount of offered ETH to a Smart Contract. The Smart
      Contract is configured to hold the offered ETH until either the offer is
      accepted by the Owner of the Item, a higher offer is received, or the
      offer is revoked. The Owner of the Item has the unilateral authority to
      accept the bid.
    </p>
    <br />

    <h3>CILO Marketplace Prices & Fees</h3>
    <br />
    <p>
      Every transaction on the CILO Marketplace is subject to Fees collected
      to support the Artists and the CILO Platform. The amount of Fees for the
      sale of a CILO Item also depends on whether the transaction involves the
      CILO Item’s first sale (“Primary Sale”) or a subsequent sale in the
      secondary market (“Secondary Market Sale”). All Fees are collected and
      distributed at the time of the purchase of a CILO Item by operation of
      the Smart Contracts.
    </p>
    <br />

    <h3>Primary Sale</h3>
    <br />
    <ul>
      <li>
        The Artist receives 90% of the Total Sale Price for Primary Sales.
      </li>
      <li>CILO collects 10% of the Total Sale Price as a commission.</li>
    </ul>

    <br />

    <h3>Secondary Market Sale</h3>
    <br />
    <ul>
      <li>
        Artists receive a 7 ~ 9.9% Royalty based on the total Sale Price for
        Secondary Market Sales paid by the Collector who sells the CILO Item.
      </li>
      <li>
        The Royalty is deducted from the total Sale Price at the time of the
        purchase by operation of the Smart Contracts. For example, based on 10%
        royalty, a Collector selling a CILO Item for 1 ETH on the Secondary
        Market will receive only 0.80 ETH, with the Artist and CILO each
        respectively receiving 0.10 ETH.
      </li>
    </ul>
    <br />

    <h3>
      Users Agree to the Automated Collection and Disbursement of Fees by the
      Smart Contracts
    </h3>
    <br />
    <p>
      The User agrees and understands that all fees, commissions, and royalties
      are transferred, processed, or initiated directly through one or more of
      the Smart Contracts on the Ethereum blockchain network. By transacting on
      the platform and by using the Smart Contracts, the User hereby
      acknowledges, consents to, and accepts all automated fees, commissions,
      and royalties for the sale of Items on the CILO Platform. The User
      hereby consents to and agrees to be bound by the Smart Contracts’
      execution and distribution of the fees, commissions, and royalties. Users
      hereby waive any entitlement to royalties, commissions, or fees paid to
      another by operation of the Smart Contracts.
    </p>
    <br />

    <h3>Users Consent to Automated Royalties to Artists</h3>
    <br />
    <p>
      The User consents to the automated collection and disbursement to Artists
      of royalties for Secondary Market sales of CILO Items. The User hereby
      waives any first sale defense or argument with respect to Secondary Market
      activities resulting in royalty to a CILO Artist.
    </p>
    <br />

    <h3>CILO Makes No Representations on Price or Value</h3>
    <br />
    <p>
      Users acknowledge and consent to the risk that the price of an Item
      purchased on the CILO marketplace may have been influenced by User
      activity outside of the control of CILO or the CILO Platform. CILO
      does not represent, guarantee, or warrant the accuracy or fairness of the
      price of any CILO Item sold or offered for sale on or off of the
      Marketplace. The User agrees and acknowledges that CILO is not a
      fiduciary nor owes any duties to any User of the platform, including the
      duty to ensure fair pricing of CILO Items or to police User behavior on
      the Marketplace.
    </p>
    <br />

    <h3>Off-Market Transactions</h3>
    <br />
    <p>
      CILO does not generally collect any fees, commissions, or royalties for
      transactions occurring outside of the CILO Marketplace and not involving
      the CILO Smart Contracts. To support the CILO Artists and the
      Platform, we encourage Collectors to list Items for sale on the CILO
      Marketplace, however, Collectors are permitted to sell or transfer their
      Items on third party exchanges. The Artist and all other Users hereby
      waive any entitlement to royalties, commissions, or fees for off-market
      transactions. The User irrevocably releases, acquits, and forever
      discharges CILO and its subsidiaries, affiliates, officers, and
      successors of any liability for royalties, fines, or fees not received by
      the User from any off-market transaction.
    </p>
    <br />

    <h3>Gas</h3>
    <br />
    <p>
      All User transactions on the CILO Platform, including without limitation
      minting, tokenizing, bidding, listing, offering, purchasing, or
      confirming, are facilitated by Smart Contracts existing on the Ethereum
      network. The Ethereum network requires the payment of a transaction fee (a
      “Gas fee”) for every transaction that occurs on the Ethereum network, and
      thus every transaction occurring on the CILO Platform. The value of the
      Gas Fee changes, often unpredictably, and is entirely outside of the
      control of CILO or the CILO Platform. The User acknowledges that under
      no circumstances will a contract, agreement, offer, sale, bid, or other
      transaction on the Platform be invalidated, revocable, retractable, or
      otherwise unenforceable on the basis that the Gas Fee for the given
      transaction was unknown, too high, or otherwise unacceptable to a User.
      Users also acknowledge and agree that gas is non-refundable under all
      circumstances.
    </p>
    <br />

    <h3>Taxes</h3>
    <br />
    <p>
      Users are responsible to pay any and all sales, use, value-added and other
      taxes, duties, and assessments now or hereafter claimed or imposed by any
      governmental authority, “ associated with your use of the Platform
      (including, without limitation, any taxes that may become payable as the
      result of your ownership, transfer, purchase, sale, or creation of any
      artworks).
    </p>
    <br />

    <h3>CILO Auctions</h3>
    <br />
    <p>
      No art marketplace would be complete without an auction house, which is
      why CILO has expanded upon its core set of smart contracts to introduce
      Auctions. But delivering the excitement of a live, peer-to-peer digital
      auction within a smart contract-enabled art marketplace introduces certain
      risks for auction participants. CILO is steadily progressing the
      decentralization of art through these new market mechanics, but not
      without ensuring that its Users understand, acknowledge, and accept the
      risks of participating in Auctions. By accepting these Terms or using the
      Platform, the User affirmatively acknowledges and agrees to the CILO
      Auction Terms of Use, which are incorporated herein.
    </p>
    <br />

    <h3>Scheduled Auctions</h3>
    <br />
    <p>
      In a Scheduled Auction, the Owner establishes a specific start and end
      time for an Auction for artwork to go live. Owners may optionally include
      a Starting Price. As long as the Starting Price is met by an Auction
      Participant, the work will be sold. Works without a Starting Price will be
      sold to the highest bidder, no matter what the bid is. A Scheduled Auction
      may be canceled prior to the start of the auction, i.e. when it is
      counting down to kick-off. After the auction begins, it cannot be stopped,
      canceled, or undone. Owners that initiate Scheduled Auctions should be
      prepared to sell their works at the Starting Price, or at any price if no
      Starting Price is established.
    </p>
    <br />

    <h3>Reserve Auctions</h3>
    <br />
    <p>
      In a Reserve Auction, an Auction for artwork is configured to
      automatically go live once a bid is received equal or greater to a public
      Reserve Price established by the Owner. Once the Reserve Price is met, the
      Auction is instantiated and configured to last for approximately 24-hours.
      The work goes to the highest bidder at the end of the Auction. In a
      Reserve Auction, an Auction for artwork is configured to automatically go
      live once a bid is received equal or greater to a public Reserve Price
      established by the Owner. Once the Reserve Price is met, the Auction is
      instantiated and configured to last for approximately 24-hours. The work
      goes to the highest bidder at the end of the Auction.
    </p>
    <br />

    <h3>Marketplace Transactions are Executed by Smart Contracts</h3>
    <br />
    <p>
      To initiate a transaction on the CILO Marketplace, a User must
      voluntarily invoke one or more Smart Contract operations from an Ethereum
      Wallet. All transactions on the CILO Marketplace, including but not
      limited to transfers, offers, bids, listings, sales, or purchases of
      CILO Items are initiated through one or more Smart Contracts at the sole
      discretion and at the complete risk of the Users. The Smart Contracts are
      configured to facilitate the execution of a voluntary User offer, an
      acceptance of an offer, or other confirmation to purchase, sell, bid on,
      list, or transfer a CILO Item. The User acknowledges the risk of Smart
      Contracts and agrees to be bound by the outcome of any Smart Contract
      operation by invoking, calling, requesting, or otherwise engaging with the
      Smart Contract, whether or not the Smart Contract behaves as the User
      expects.
    </p>
    <br />

    <h3>
      User Acknowledges the Risk of Smart Contracts and Blockchain Technology
    </h3>
    <br />
    <p>
      CILO Marketplace transactions, including but not limited to primary
      sales, secondary market sales, listings, offers, bids, acceptances, and
      other operations through the Site utilize experimental smart contract and
      blockchain technology, including non-fungible tokens, cryptocurrencies,
      consensus algorithms, and decentralized or peer-to-peer networks and
      systems. Users acknowledge and agree that such technologies are
      experimental, speculative, and inherently risky. Users acknowledge and
      agree that the CILO smart contracts may be subject to bugs,
      malfunctions, timing errors, hacking and theft, or changes to the protocol
      rules of the Ethereum blockchain (i.e., "forks"), which can adversely
      affect the smart contracts and may expose you to a risk of total loss,
      forfeiture of your digital currency or CILO Items, or lost opportunities
      to buy or sell CILO Items. CILO assumes no liability or responsibility
      for any such smart contract or related failures, risks, or uncertainties.
    </p>
    <br />

    <h3>Users Assume the Risk of Interacting with on Smart Contracts</h3>
    <br />
    <p>
      Users hereby acknowledge and assume the risk of initiating, interacting
      with, participating in Marketplace or Auction transactions and take full
      responsibility and liability for the outcome of any transaction they
      initiate, whether or not the smart contracts, the CILO site, or other
      market participants behave as expected or intended. Users hereby represent
      that they are knowledgeable, experienced and sophisticated in using
      blockchain technology, the CILO site, and in initiating Ethereum-based
      transactions.
    </p>
    <br />

    <h3>No Attacking or Interfering with the Smart Contracts</h3>
    <br />
    <p>
      Users are forbidden from engaging in any attack, hack, denial-of-service
      attack, interference, or exploit of any CILO smart contract. Operations
      performed by a User that is technically permitted by a CILO smart
      contract may nevertheless be a violation of our Terms of Service and the
      law.
    </p>
    <br />

    <h3>CILO Makes No Representations or Warranties</h3>
    <br />
    <p>
      CILO makes no representations or warranties, express or implied, written
      or oral, made by or on behalf of CILO in connection therewith, including
      any representations or warranties of title, non-infringement,
      functionality, merchantability, usage, security, suitability or fitness
      for any particular purpose, workmanship or technical quality of any CILO
      Item or work, smart contract code, or software.
    </p>
    <br />

    <h3>CILO is Not Liable for the Outcome of Any Marketplace Transaction</h3>
    <br />
    <p>
      CILO shall not be liable to you for contract, tort, or any other types
      of damages, including indirect, special, incidental, consequential,
      punitive or exemplary damages arising out of or related to participation
      in or the outcome of a Marketplace transaction, whether or not CILO has
      been advised or knew of the possibility of such damages. CILO's
      liability for damages under this Agreement shall in all cases be limited
      to, and under no circumstances shall exceed, the number of proceeds
      actually received by CILO arising out of the damage after deducting all
      costs, fees, and liabilities incurred by or on behalf of CILO.
    </p>
    <br />

    <h3>CILO May Modify these Terms and the Smart Contracts</h3>
    <br />
    <p>
      CILO reserves, the right, in its sole and absolute discretion, to amend,
      modify, alter or supplement these Terms and the smart contracts accessible
      through the CILO Site from time to time.
    </p>
    <br />

    <h3>The Marketplace Smart Contracts Are Not Controlled By CILO</h3>
    <br />
    <p>
      All Marketplace transactions are executed by one or more Smart Contracts
      processed on the Ethereum Virtual Machine and not under any direct control
      by CILO or any other third party. The User acknowledges and agrees that
      CILO is not a party to any agreement or transaction between any Users
      involving the purchase, offer, sale, auction, or transfer of CILO Items,
      whether or not a commission or fee is received by CILO as a consequence
      of the transaction. Items listed for sale on the CILO Marketplace are
      not offered on consignment or held in trust on behalf of any Artist,
      Collector, Owner, or User. CILO reserves the right to execute Smart
      Contract transactions on the CILO Marketplace as a collector of CILO
      items.
    </p>
    <br />

    <h3>Marketplace Conduct</h3>
    <br />
    <p>
      The CILO Marketplace is a transparent and open digital art Marketplace
      that depends on the honest participation of all Users.
    </p>
    <br />

    <h3>Users are Prohibited from Manipulating Market Prices</h3>
    <br />
    <p>
      Users are expressly forbidden from accepting, soliciting, offering,
      bidding, engaging with the Smart Contracts, or otherwise transacting on or
      off of the CILO Platform with the intent to artificially devalue,
      inflate, or otherwise deceptively influence, misrepresent, or cause to be
      misrepresented the price a CILO Item, groups of CILO Items, or CILO
      Items created by particular Artists.
    </p>
    <br />

    <h3>
      Users are Prohibited from Bidding on, Making Offers on, or Purchasing
      Their Own Works
    </h3>
    <br />
    <p>
      CILO Artists, Owners, and Collectors are expressly forbidden from
      bidding, purchasing, or making offers on their own listed or offered
      CILO Items, especially for the purpose of artificially influencing the
      price of the listed Item(s).
    </p>
    <br />

    <h3>Users are Prohibited from Anticompetitive Conduct</h3>
    <br />
    <p>
      Users are generally forbidden from engaging in any deceptive conduct that
      may prevent competitive or fair bidding, artificially inflate or deflate
      the price of a work, simulate demand for work (i.e, "wash trading"), or
      any other anti-competitive bidding conduct such as but not limited to
      “puffing,” “chill bidding,” “shill bidding,” “sham bidding,” or “sock
      puppet bidding.”
    </p>
    <br />

    <h3>
      Users are Prohibited from Using the Marketplace to Conceal Economic
      Activity
    </h3>
    <br />
    <p>
      Users are expressly forbidden from selling works, listing items for
      Auction, making bids during an auction, purchasing a work, or engaging in
      any other Marketplace transaction for the purpose of concealing economic
      activity, laundering money, or financing terrorism. For example, and
      without limitation, Users are forbidden from using the Platform and any
      Smart Contract to conceal or transfer proceeds or assets relating to
      criminal activity or to pay for an Item for any other reason than to
      obtain the Item. All Users expressly represent and warrant by performing
      transactions involving the CILO Site or Smart Contracts that their
      actions are legal under United States law and the jurisdiction from which
      they initiate the transaction.
    </p>
    <br />

    <h3>Users Agree to Report Suspicious Market Activity</h3>
    <br />
    <p>
      Users agree to report suspicious market activity of other CILO Users. If
      a User suspects that one or more CILO Users are in violation of these
      Terms, the User should promptly inform the CILO team at cs@CILO.io.
    </p>
    <br />

    <h3>
      Violation of the Marketplace Code of Conduct May Result in CILO
      Intervention
    </h3>
    <br />
    <p>
      The User hereby agrees and acknowledges that any forbidden Marketplace
      conduct described herein and in the may be a violation of federal or state
      law and/or these Terms. CILO hereby reserves the right to completely or
      partially restrict or revoke a User’s access to the Site for violating
      these Terms. CILO reserves the right to amend, rectify, edit, or
      otherwise alter CILO Market transaction data to mitigate market harm
      caused by a User’s violation of these terms.
    </p>
    <br />

    <h3>
      User Releases CILO from Claims Arising From Other Users’ Violations of
      these Terms
    </h3>
    <br />
    <p>
      The User irrevocably releases, acquits, and forever discharges CILO and
      its subsidiaries, affiliates, officers, and successors for and against any
      and all past or future causes of action, suits, or controversies arising
      out of another User’s violation of these Terms.
    </p>
    <br />

    <h2>2. Ownership</h2>
    <br />
    <p>
      All works Minted on the Platform are subject to the CILO License, the
      terms of which are described below. All Users who receive a CILO Item
      acknowledge and agree to accept or purchase the Item subject to the
      conditions of the License.
    </p>
    <br />

    <h3>Ownership of a CILO Item</h3>
    <br />
    <p>
      Owning a CILO Item is similar to owning a piece of physical art. You own
      a cryptographic token representing the Artist’s creative Work as a piece
      of property, but you do not own the creative Work itself. Collectors may
      show off their ownership of collected CILO Items by displaying and
      sharing the Underlying Artwork, but Collectors do not have any legal
      ownership, right, or title to any copyrights, trademarks, or other
      intellectual property rights to the underlying Artwork, excepting the
      limited license granted by these Terms to Underlying Artwork. The Artist
      reserves all exclusive copyrights to Artworks underlying CILO Items
      Minted by the Artist on the Platform, including but not limited to the
      right to reproduce, to prepare derivative works, to display, to perform,
      and to distribute the Artworks.
    </p>
    <br />

    <h3>The Collector’s Limited License to a Minted Artwork</h3>
    <br />
    <p>
      Owning a CILO Item is also different than owning a piece of physical
      art. Art on the CILO Platform is digital, meaning that it is inherently
      easier to share, display, replicate, and distribute around cyberspace. Our
      Community Guidelines encourage Collectors to show off, promote, and share
      their collected Items, but the Collectors may not infringe on any of the
      exclusive rights of the copyright holder (i.e., the Artist). By minting
      Items on the Platform, Artist affirmatively grants a limited, worldwide,
      non-assignable, non-sublicensable, royalty-free license to display the
      Artwork underlying CILO Items legally owned and properly obtained by the
      Collector.
    </p>
    <br />

    <h3>Collectors May Display the Artwork</h3>
    <br />
    <p>
      The Collector’s limited license to display the Work, or perform the Work
      in the case of audiovisual works, includes, but is not limited to, the
      right to display or perform the Work privately or publicly: (i) for the
      purpose of promoting or sharing the Collector’s purchase, ownership, or
      interest in the Work, for example, on social media platforms, blogs,
      digital galleries, or other Internet-based media platforms; (ii) for the
      purpose of sharing, promoting, discussing, or commenting on the Work;
      (iii) on third party Marketplaces, exchanges, Platforms, or applications
      in association with an offer to sell, or trade, the Token associated with
      Work; and (iv) within decentralized virtual environments, virtual worlds,
      virtual galleries, virtual museums, or other navigable and perceivable
      virtual environments, including simultaneous display of multiple copies of
      the Work within one or more virtual environments.
    </p>
    <br />

    <h3>Collectors Shall Not Make Commercial Use of Artwork</h3>
    <br />
    <p>
      Collectors have the right to sell, trade, transfer, or use their CILO
      Items, but Collectors may not make “commercial use” of the underlying Work
      including, for example, by selling copies of Work, selling access to the
      Work, selling derivative works embodying the Work, or otherwise
      commercially exploiting the Work.
    </p>
    <br />

    <h3>Other Restrictions on the Collector’s Limited License</h3>
    <br />
    <p>
      The Collector agrees that it may not, nor permit any third party, to do or
      attempt to do any of the foregoing without the Artist’s express prior
      written consent in each case: (i) modify, distort, mutilate, or perform
      any other modification to the Work which would be prejudicial to the
      Artist’s honor or reputation; (ii) use the Work to advertise, market, or
      sell any third party product or service; (iii) use the Work in connection
      with images, videos, or other forms of media that depict hatred,
      intolerance, violence, cruelty, or anything else that could reasonably be
      found to constitute hate speech or otherwise infringe upon the rights of
      others; (iv) incorporate the Work in movies, videos, video games, or any
      other forms of media for a commercial purpose, except to the limited
      extent that such use is expressly permitted by these Terms or solely for
      your Collector’s personal, non-commercial use; (v) sell, distribute for
      commercial gain, or otherwise commercialize merchandise that includes,
      contains, or consists of the Work; (vi) attempt to trademark, copyright,
      or otherwise acquire additional intellectual property rights in or to the
      Work; (vii) attempt to Mint, tokenize, or create an additional
      cryptographic token representing the same Work, whether on or off of the
      CILO Platform; (viii) falsify, misrepresent, or conceal the authorship
      of the Work or the CILO Item; or (ix) otherwise utilize the Work for the
      Collector’s or any third party’s commercial benefit.
    </p>
    <br />

    <h3>
      The Limited License Belongs Only to the Current Owner of a CILO Item
    </h3>
    <br />
    <p>
      The User agrees and acknowledges that the lawful ownership, possession,
      and title to a CILO Item is a necessary and sufficient condition
      precedent to receiving the limited license rights to the underlying Work
      provided by these Terms. Any subsequent transfer, dispossession, burning,
      or another relinquishment of a CILO Item will immediately terminate the
      former Owner’s rights and interest in the license or CILO Item as
      provided by these Terms.
    </p>
    <br />

    <h2>3. Rights & Restrictions.</h2>
    <br />

    <h3>The Artist’s Rights and Restrictions</h3>
    <br />
    <p>
      The Artist owns all legal rights, titles, and interests in all
      intellectual property rights to creative Works underlying CILO Items
      Minted by the Artist on the Platform, including but not limited to
      copyrights and trademarks. As the copyright owner, the Artist enjoys
      several exclusive rights to the Work, including the right to reproduce,
      the right to prepare derivative works, the right to distribute, and the
      right to display or perform the Art. Subject to, and in accordance with
      these Terms, the Artist hereby acknowledges, understands, and agrees that
      minting a Work on the Platform constitutes an express and affirmative
      grant of the limited license rights to the Work to all subsequent Owners
      of the CILO Item, as provided herein.
    </p>
    <br />

    <h3>Artist Agrees Not to Mint Multiple Tokens for the Same Artwork</h3>
    <br />
    <p>
      CILO Items are unique, meaning that there should only ever be one
      digital token for a given Work of Art. Artist hereby acknowledges,
      understands, and agrees that minting a Work on the Platform constitutes an
      express representation, warranty, and covenant that the Artist has not,
      will not, and will not cause another to Mint, tokenize, or create another
      cryptographic token representing a digital collectible for the same Work,
      excepting, without limitation, the Artist’s ability to Mint, tokenize, or
      create a cryptographic token or other digital asset representing a legal,
      economic, or other interest relating to any of the exclusive rights
      belonging to the Artist under copyright law.
    </p>
    <br />

    <h3>Artist Grants CILO a License to All Minted Works</h3>
    <br />
    <p>
      The Artist hereby acknowledges, understands, and agrees that minting a
      Work on the Platform constitutes an express and affirmative grant to
      CILO, its affiliates and successors a non-exclusive, world-wide,
      assignable, sub-licensable, perpetual, and royalty-free license to make
      copies of, display, perform, reproduce, and distribute the Work on any
      media whether now known or later discovered for the broad purpose of
      operating, promoting, sharing, developing, marketing, and advertising the
      Platform, the Site, the Marketplace, or any other purpose related to the
      CILO Platform or business, including without limitation, the express
      right to: (i) display or perform the Work on the Site, a third party
      platform, social media posts, blogs, editorials, advertising, market
      reports, virtual galleries, museums, virtual environments, editorials, or
      to the public; (ii) create and distribute digital or physical derivative
      works based on the Work, including without limitation, compilations,
      collective works, and anthologies; (iii) indexing the Work in electronic
      databases, indexes, catalogues, the Smart Contracts, or ledgers; and (iv)
      hosting, storing, distributing, and reproducing one or more copies of the
      Work within a distributed file keeping system, node cluster, or other
      database (e.g., IPFS) or causing, directing, or soliciting others to do
      so.
    </p>
    <br />

    <h3>User Releases CILO from Copyright Claims</h3>
    <br />
    <p>
      The Artist and all Users irrevocably release, acquit, and forever
      discharge CILO and its subsidiaries, affiliates, officers, and
      successors of any liability for direct or indirect copyright or trademark
      infringement for CILO's use of a Work in accordance with these Terms,
      including without limitation, CILO solicitation, encouragement, or
      request for Users or third parties to host the Work for the purpose of
      operating a distributed database and CILO's deployment or distribution
      of a reward, a token, or any other digital asset to Users or third parties
      for hosting Works on a distributed database.
    </p>
    <br />

    <h2>4. Termination</h2>
    <br />
    <p>
      You may terminate these Terms at any time by canceling your account on the
      Platform and discontinuing your access to and use of the Platform. You
      will not receive any refunds if you cancel your account, or otherwise
      terminate these Terms. You agree that we, in our sole discretion and for
      any or no reason, may terminate these Terms and suspend and/or terminate
      your account(s) for the Platform. You agree that any suspension or
      termination of your access to the Platform may be without prior notice and
      that we will not be liable to you or to any third party for any such
      suspension or termination. If we terminate these Terms or suspend or
      terminate your access to or use of the Platform due to your breach of
      these Terms or any suspected fraudulent, abusive, or illegal activity,
      then termination of these Terms will be in addition to any other remedies
      we may have at law or in equity. Upon any termination or expiration of
      these Terms, whether by you or us, you may no longer have access to
      information that you have posted on the Platform or that is related to
      your account, and you acknowledge that we will have no obligation to
      maintain any such information in our databases or to forward any such
      information to you or to any third party.
    </p>
    <br />

    <h2>5. Disclaimers and Limitations on Our Liability</h2>
    <br />

    <h3>CILO Makes No Representations or Warranties</h3>
    <br />
    <p>
      TO THE EXTENT PERMITTED BY APPLICABLE LAW, CILO AND ITS AFFILIATES MAKE
      NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF
      CONTENT AVAILABLE ON OR THROUGH THE SITE OR ABOUT PRODUCTS, THE VALUE OR
      TITLE OF CILO ITEMS, OR THE CONTENT OF ANY THIRD-PARTY WEBSITES OR
      SERVICES LINKED TO OR INTEGRATED WITH OUR SITE. CILO AND ITS AFFILIATES
      WILL HAVE NO LIABILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF
      CONTENT; (B) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS
      TO OR USE OF THE SITE OR CONSUMPTION OF ANY PRODUCTS; (C) ANY UNAUTHORIZED
      ACCESS TO OR USE OF OUR SERVERS OR OF ANY PERSONAL INFORMATION OR USER
      DATA; (D) ANY INTERRUPTION OF TRANSMISSION TO OR FROM THE SITE; (E) ANY
      BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR
      THROUGH THE SITE OR SERVICES BY ANY THIRD PARTY; (F) ANY LOSS OR DAMAGE OF
      ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED
      THROUGH THE SITE OR SERVICES; OR (G) LOSS OR DAMAGED CAUSED BY ANOTHER
      USER’S VIOLATION OF THESE TERMS. TO THE EXTENT PERMITTED BY LAW, OUR
      COMPANY AND ITS AFFILIATES WILL NOT BE RESPONSIBLE FOR LOST PROFITS,
      REVENUES, OR DATA, FINANCIAL LOSSES, OR INDIRECT, SPECIAL, CONSEQUENTIAL,
      EXEMPLARY, OR PUNITIVE DAMAGES.
    </p>
    <br />

    <h3>Limitation on Liability</h3>
    <br />
    <p>
      TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF OUR COMPANY AND ITS
      AFFILIATES, FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED
      WARRANTIES, IS LIMITED TO THE TOTAL AMOUNT YOU PAID TO US IN FEES OVER THE
      PAST TWELVE (12) MONTHS. Some jurisdictions do not allow the exclusion of
      certain warranties and limitations of liability provided in this Section.
      If you are in such a jurisdiction, some of the above limitations and
      disclaimers may not apply to you. To the extent we may not, as a matter of
      applicable law, disclaim any implied warranty or limit our liabilities,
      the scope and duration of such warranty and the extent of our liability
      will be the minimum permitted by applicable law.
    </p>
    <br />

    <h2>6. Assumption Of Risk</h2>
    <br />

    <h3>User Acknowledges the Risk of Cryptocurrency and Smart Contracts</h3>
    <br />
    <p>
      YOU FURTHER REPRESENT AND WARRANT THAT YOU UNDERSTAND AND ARE WILLING TO
      ACCEPT THE RISKS ASSOCIATED WITH CRYPTOGRAPHIC SYSTEMS SUCH AS THE SMART
      CONTRACTS, THE ETHEREUM BLOCKCHAIN, NON-FUNGIBLE TOKENS, AND THE
      INTERPLANETARY FILE SYSTEM.
    </p>
    <p>
      WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS
      THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK OR THE METAMASK ELECTRONIC
      WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS
      ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY
      CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR
      DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR
      ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF
      VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE
      PLATFORM, ETHEREUM NETWORK, THE METAMASK ELECTRONIC WALLET OR
      ETHEREUM-COMPATIBLE BROWSER OR WALLET.
    </p>
    <br />

    <h3>
      CILO is Not Responsible for Technical Errors on the Ethereum Blockchain
    </h3>
    <br />
    <p>
      CILO IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER
      FEATURES OF THE ETHEREUM NETWORK OR THE METAMASK ELECTRONIC WALLET OR ANY
      ETHEREUM-COMPATIBLE BROWSER OR WALLET, INCLUDING BUT NOT LIMITED TO LATE
      REPORTS BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY
      ISSUES WITH THE BLOCKCHAIN SUPPORTING THE ETHEREUM NETWORK, INCLUDING
      FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A
      RESULT.
    </p>
    <p>
      Upgrades by Ethereum to the Ethereum platform, a hard fork in the Ethereum
      platform, or a change in how transactions are confirmed on the Ethereum
      platform may have unintended, adverse effects on all blockchains using the
      ERC-20, ERC-721, or ERC-1155 standards, including the CILO ecosystem.
    </p>
    <br />

    <h3>The User Acknowledges the Risks of the Platform</h3>
    <br />
    <p>
      You acknowledge that the Platform is subject to flaws and acknowledge that
      you are solely responsible for evaluating any code provided by the
      Platform. This warning and others provided in this Agreement by CILO in
      no way evidence or represent an ongoing duty to alert you to all of the
      potential risks of utilizing or accessing the Platform. The Platform may
      experience sophisticated cyber-attacks, unexpected surges in activity, or
      other operational or technical difficulties that may cause interruptions
      to or delays on the Platform. You agree to accept the risk of the Platform
      failure resulting from unanticipated or heightened technical difficulties,
      including those resulting from sophisticated attacks, and you agree not to
      hold us accountable for any related losses. We will not bear any
      liability, whatsoever, for any damage or interruptions caused by any
      viruses that may affect your computer or other equipment, or any phishing,
      spoofing, or other attacks.
    </p>
    <br />

    <h3>CILO Does Not Guarantee the Value or Title of CILO Items</h3>
    <br />
    <p>
      The prices of blockchain assets are extremely volatile. Fluctuations in
      the price of other digital assets could materially and adversely affect
      the value of your CILO Items, which may also be subject to significant
      price volatility. A lack of use or public interest in the creation and
      development of distributed ecosystems could negatively impact the
      development of the CILO ecosystem, and therefore the potential utility
      or value of CILO Items. The Platform, ETH, and digital assets could be
      impacted by one or more regulatory inquiries regulatory actions, or
      legislative policies which could impede or limit the ability of CILO to
      continue to develop the CILO Platform, or which could impede or limit
      your ability to access or use the Platform or Ethereum blockchain. CILO
      Items may be encumbered by actual or possible copyright or trademark
      claims against the Item.
    </p>
    <br />

    <h3>User Acknowledges Financial Risk of Digital Assets</h3>
    <br />
    <p>
      Use of the Platform, including the creating, buying, or selling trading
      digital assets, may carry financial risk. digital assets are, by their
      nature, highly experimental, risky, volatile and transactions carried
      through the Platform are irreversible, final and there are no refunds. You
      acknowledge and agree that you will access and use the Platform at your
      own risk. The risk of loss in trading digital assets can be substantial.
      You should, therefore, carefully consider whether creating, buying, or
      selling digital assets is suitable for you in light of your circumstances
      and financial resources. By using the Platform, you represent that you
      have been, are, and will be solely responsible for making your own
      independent appraisal and investigations into the risks of a given
      transaction and the underlying digital assets. You represent that you have
      sufficient knowledge, market sophistication, professional advice, and
      experience to make your own evaluation of the merits and risks of any
      transaction conducted via the Platform or any underlying digital asset.
      You accept all consequences of using the Platform, including the risk that
      you may lose access to your digital assets indefinitely. All transaction
      decisions are made solely by you. Notwithstanding anything in this
      Agreement, we accept no responsibility whatsoever for and will in no
      circumstances be liable to you in connection with the use of the Platform
      for performing digital asset transactions. Under no circumstances will the
      operation of all or any portion of the Platform be deemed to create a
      relationship that includes the provision or tendering of investment
      advice.
    </p>
    <br />

    <h2>7. Governing Law and Jurisdiction</h2>
    <p>
      These Terms will be governed by and construed under the laws of the
      Province of Ontario, without giving effect to any conflict of laws, rules,
      or provisions. You agree that any action of whatever nature arising from
      or relating to these Terms, the Site, or our Products will be filed only
      in the province or federal courts in Ontario. You consent and submit to
      the personal jurisdiction of such courts for any such action. The failure
      by us to enforce any right or provision of these Terms will not prevent us
      from enforcing such right or provision in the future. We may assign our
      rights and obligations under these Terms, including in connection with a
      merger, acquisition, sale of assets or equity, or by operation of law.
    </p>
    <br />

    <h2>8. Changes to this Agreement.</h2>

    <br />

    <h3>These Terms May Change</h3>
    <br />
    <p>
      These Terms may be discretionarily modified or replaced at any time unless
      stated otherwise herein. The most current version of this Agreement will
      be posted on the Site with the “Last Revised” date at the top of the
      Agreement changed. Any changes or modifications will be effective
      immediately upon posting the revisions to the Site. You shall be
      responsible for reviewing and becoming familiar with any such
      modifications. You waive any right you may have to receive specific notice
      of such changes or modifications. Use of the Platform by you after any
      modification to the Agreement constitutes your acceptance of the Agreement
      as modified. If you do not agree to the Agreement in effect when you
      access or use the Platform, you Platform. We may, at any time and without
      liability or prior notice, modify or discontinue all or part of the
      Platform (including access to the Platform via any third-party links).
    </p>
    <br />

    <h3>The Smart Contracts May Change</h3>
    <br />
    <p>
      The User acknowledges that CILO may modify, change, amend, or replace
      one or more of the Smart Contracts from time to time. The User agrees that
      a modification to one or more of the Smart Contracts does not alter any
      right or obligation conferred by these Terms.
    </p>
    <br />

    <h2>9. Eligibility:</h2>
    <br />
    <p>
      You affirm that you are over the age of 18, as the Platform is not
      intended for children under 18. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE
      OF 18, OR THE LEGAL AGE OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION
      HAS AN OLDER AGE OF MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH
      YOUR PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR
      GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR
      PARENT OR GUARDIAN REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU
      ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS FOR THE BENEFIT OF A CHILD
      OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT CHILD’S
      USE OF THE PLATFORM, INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY
      THAT HE OR SHE MAY INCUR.
    </p>
    <br />

    <h2>10. Indemnity:</h2>
    <br />
    <p>
      You agree to indemnify and hold harmless our company and its affiliates
      from and against any and all claims, costs, proceedings, demands, losses,
      damages, and expenses (including, without limitation, reasonable
      attorney’s fees and legal costs) of any kind or nature, arising from or
      relating to, any actual or alleged breach of these Terms by you, a
      co-conspirator, or anyone using your account. If we assume the defense of
      such a matter, you will reasonably cooperate with us in such defense.
    </p>
    <br />

    <h2>11. Severability</h2>
    <br />
    <p>
      If any provision of these Terms is found to be unlawful or unenforceable,
      then that provision will be deemed severable from these Terms and will not
      affect the enforceability of any other provisions.
    </p>
    <br />

    <h2>12. Contact Us</h2>
    <br />
    <p>
      If you have any questions or concerns, including if you need to access
      this Agreement in an alternative format, we encourage you to contact us
      via e-mail at cs@CILO.io.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2 {
  font-size: 1.4rem;
  font-weight: 600;
}
h3 {
  font-weight: 600;
}
p {
  padding-left: 1rem;
  text-align: justify;
}
li {
  list-style: circle;
  margin-left: 3rem;
  text-align: justify;
}
</style>